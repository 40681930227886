import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Api, cancelRequest } from '../../api/Assinafy'
import { Dashboard } from '../../layout'
import { Alert, Form, Status, Icon, Input, Button, Dropdown, Dropzone, Pagination } from '../../components'
import { statusFilter } from '../../utils/Documents'
import TemplatesList from './TemplatesList'
import TemplatesNotFound from './TemplatesNotFound'
import _ from 'lodash';

import './styles.scss';

const searchParamsToObject = (entries) => {
  const result = {}

  for (const [key, value] of entries) {
    if (key === 'page') continue;

    result[key] = value
  }

  return result
}

const Templates = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')

  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState()
  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState(searchParamsToObject(searchParams.entries()) || {})
  const [pagination, setPagination] = useState(false)
  const [dropzoneShow, setDropzoneShow] = useState(false)
  const [retries, setRetries] = useState(0)
  
  const cancelToken = cancelRequest()

  const dropzoneRef = useRef()
  const dropdownRef = useRef()

  /* TODO - implementar loading ao filter e trocar de page */

  const handleFieldValueChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleStatusListClick = (statuses) => {
    if (statuses) {
      if (_.isEqual(statuses, formData.status)) {
        return
      }

      setFormData({
        ...formData,
        ['status']: statuses
      })
    } else {
      setFormData((prevState) => {
        delete prevState.status
        return prevState
      })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setSearchParams(formData)
    getDocuments()
  }

  const uploadInit = () => {
    dropdownRef.current?.close()

    setDropzoneShow(true)
  }

  const uploadRemove = () => setDropzoneShow(false)

  const uploadOpen = (documentId) => {
    navigate(`/dashboard/templates/${documentId}/prepare`)
  }

  const getDocuments = () => {
    setLoading(true)

    Api('template/index')({
      ...cancelToken.config,
      params: {
        'page': page || 1,
        'per-page': 8,
        ...formData
      }
    })
      .then(({ status, headers, data: { data } }) => {
        if (status === 200 && (data.length || Object.keys(formData).length)) {
          setDocuments(data)
          setLoading(false)
        }

        setPagination({
          pageCount: headers['x-pagination-page-count'],
          perPage: headers['x-pagination-per-page'],
          totalCount: headers['x-pagination-total-count']
        })
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    let timerId;

    setDropzoneShow(!documents && !Object.keys(formData).length)

    if (documents?.length) {
      const hasDocumentProcessing = documents.find((document) =>
        ['uploading', 'uploaded', 'metadata_processing'].indexOf(document.status) > -1
      )
      if (hasDocumentProcessing) {
        timerId = _.delay(() => {
          getDocuments()
          setRetries(retries + 1)
        }, 1000 * Math.pow(2, retries));
      } else {
        setRetries(0)
      }

      if (dropzoneShow) {
        _.delay(() => setDropzoneShow(false), 3000)
      }
    }

    return () => {
      clearTimeout(timerId)
      cancelToken.cancel()
    }
  }, [documents])

  useEffect(() => {
    getDocuments()
  }, [page])

  return (<Dashboard.Content>
    <Dashboard.Header
      title="Templates"
    />

    <Alert show={errors.length} variant="warning" onClose={() => setErrors([])}>
      {errors.map((error) => error)}
    </Alert>

    {documents &&
      <Dashboard.Filter>
        <Form id="form-documents-filter"
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Input.Group>
              <Input.Search
                name="search"
                placeholder="Busque pelo nome do template"
                onChange={handleFieldValueChange}
                defaultValue={formData.search}
              />
              <Button variant="action" submit>
                <span className="d-lg-none">
                  <Icon id="icon-zoom" size="20" />
                </span>
                <span className="d-none d-lg-block">
                  BUSCAR
                </span>
              </Button>
            </Input.Group>

            <Dashboard.FixedBottom>
              <Dropdown
                align="right"
                button={<>
                  <Icon id="icon-arrow_upward" className="me-1" size="18" />
                  CRIAR TEMPLATE
                </>}
                variant="primary"
                ref={dropdownRef}
              >
                <Dropdown.Item as="button" type="button"
                  onClick={() => dropzoneRef.current.open()}
                >
                  <Icon id="icon-upload_file" className="me-1" size="20" />
                  Enviar do Computador
                </Dropdown.Item>
                {/* TODO: Google Drive integration
                <Dropdown.Item to={'/google-drive'}>
                  <Icon id="icon-google_drive" className="me-1" size="20" />
                  Importar do Drive
                </Dropdown.Item>
                */}
              </Dropdown>
              <Button variant="primary"
                className="btn-scroll-top"
                onClick={() => dropzoneRef.current.open()}
              >
                <Icon id="icon-arrow_upward" />
              </Button>
            </Dashboard.FixedBottom>
          </Form.Group>
        </Form>
      </Dashboard.Filter>
    }

    
    <Dropzone.Document show={dropzoneShow} ref={dropzoneRef}
      onSubmit={Api('template/upload')}
      onComplete={getDocuments}
      onOpen={uploadOpen}
      onInit={uploadInit}
      onRemove={uploadRemove}
      size={((!documents || !documents.length) && !Object.keys(formData).length) && 'lg'}
      title="Você ainda não possui templates na sua lista"
      description="Adicione o seu primeiro template para enviar documentos clicando no botão abaixo"
      buttonText="CRIAR TEMPLATE"
      icon="template"
      buttonIcon="template"
    />

    {(documents && !documents.length && !!Object.keys(formData).length) &&
      <TemplatesNotFound search={searchParams.get('search')} />
    }

    {(documents && !!documents.length) &&
      <>
        {!!searchParams.get('search') &&
          <h2 className="h2">
            {pagination.totalCount} resultados para busca - {searchParams.get('search')}
          </h2>
        }
        <TemplatesList rows={loading ? null : documents} />
        {pagination &&
          <Pagination {...pagination} />
        }
      </>
    }
  </Dashboard.Content>);
};

export default Templates;