import React from 'react';
import classNames from 'classnames';

import sprite from '../../assets/images/sprite.svg';
import './styles.scss';

const Icon = ({ size, color, ...props }) => {
  const { className } = props;
  const newClassName = classNames('icon', props.id, className);

  if (!size) {
    size = 24
  }

  return (
    <svg
      className={newClassName}
      height={size}
      width={size}
      style={ color && { fill: color }}
    >
      <use href={`${sprite}#${props.id}`}></use>
    </svg>
  );
};

export default Icon;
