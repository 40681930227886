import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Api, cancelRequest } from '../../api/Assinafy';
import { signerColors } from '../../utils/Signers';
import { usePrepareContext } from '../../routes/PrepareRoute';
import { PrepareNavbar } from '../../layout/Prepare';
import { Dashboard, Scroll, Section } from '../../layout';
import { Form, Table, Avatar, Textarea, Button, Icon, Input, Alert } from '../../components';

const DocumentReview = ({ virtual }) => {
  const [documentContext, setDocumentContext, signersContext, , fieldsContext,] = usePrepareContext();
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formData, setFormData] = useState({})
  const [showExpiration, setShowExpiration] = useState(false)
  const { id: documentId } = useParams()
  const navigate = useNavigate()
  const cancelToken = cancelRequest()
  const datePickerRef = useRef()

  const handleSubmit = (event) => {
    event.preventDefault()

    setLoading(true)

    if (!virtual) {
      const entries = fieldsContext.map(itemToSign => {
        return {
          pageId: itemToSign.page.id,
          fields: itemToSign.fields.map(field => {
            return {
              signerId: field.signer.id,
              fieldId: field.fieldType.id,
              displaySettings: {
                ...field.displaySettings,
                backgroundColor: field.backgroundColor
              }
            }
          })
        }
      })

      return Api('assignment/create')(documentId, {
        method: 'collect',
        entries,
        ...formData
      })
        .then(({ status }) => {
          if (status === 200) {
            navigate(`/dashboard/documents/${documentId}/sent`, {
              state: {
                name: documentContext.name
              }
            })
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    } else {
      Api('assignment/create')(documentId, {
        signerIds: signersContext.map(s => s.id),
        method: 'virtual',
        ...formData
      }).then(({ status }) => {
        if (status === 200) {
          navigate(`/dashboard/documents/${documentId}/sent`, {
            state: {
              name: documentContext.name
            }
          })
        }
      })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    }
  }

  const fieldValueChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleOpenClick = () => {
    setShowExpiration(true)
  }

  const handleCloseClick = () => {
    setShowExpiration(false)
  }

  useEffect(() => {
    Api('document/view')(documentId, {
      ...cancelToken.config
    })
      .then(({ status, data }) => {
        if (status === 200) {
          window.pageCanvas = []
          setDocumentContext(data.data)
        }
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  useEffect(() => {
    if (showExpiration) {
      fieldValueChange('expires_at', datePickerRef.current?.props.selected)
    } else {
      fieldValueChange('expires_at', '')
    }
  }, [showExpiration])

  return (<Form id="form-document-review"
    className="form-prepare"
    onSubmit={handleSubmit}
  >
    <PrepareNavbar
      document={documentContext}
      step="review"
      submitLoading={loading}
      text="Revisar e enviar"
      url="documents"
    />
    <Scroll>
      <Dashboard.Content>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Dashboard.Header
              title="Revisar e enviar"
            />

            <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
              {formError.map((error) => error)}
            </Alert>

            <SectionSigners signers={signersContext} />

            <Section>
              <Section.Header
                title="Mensagem"
                opening="Está mensagem será enviada para todos os signatários"
              />
              <Form.Group>
                <Textarea
                  name="message"
                  onChange={(event) => fieldValueChange(event.target.name, event.target.value)}
                  label="Mensagem"
                />
              </Form.Group>
            </Section>

            <Section>
              <Section.Header
                title="Validade do documento"
                opening="Deseja colocar uma data de validade para esse documento?"
              />
              {!showExpiration ?
                <Button variant="link" onClick={handleOpenClick}>
                  <Icon id="icon-date" className="me-1" size="18" />
                  DEFINIR DATA
                </Button>
                :
                <Form.Group className="group-calendar">
                  <Input.Group>
                    <Input.Date ref={datePickerRef}
                      name="expires_at"
                      className="control-datepicker"
                      onChange={(value) => fieldValueChange('expires_at', value)}
                      label="Data"
                    />
                    <Button variant="icon" onClick={handleCloseClick}>
                      <Icon id="icon-close" size="24" />
                    </Button>
                  </Input.Group>
                </Form.Group>
              }
            </Section>
          </Col>
        </Row>
      </Dashboard.Content>
    </Scroll>
  </Form>)
};

const SectionSigners = ({ signers }) => {
  return (<Section>
    <Section.Header
      title="Signatários"
      opening="Verifique se você associou todos os participantes do documento"
    />
    <Table
      rows={signers}
      renderRow={(signer, i) => <tr key={i}>
        <td>
          <div className="signer-header">
            <Avatar placeholder={signer.full_name[0] || signer.email[0]} bgColor={signerColors[i].color} />
            {signer.full_name}
          </div>
        </td>
        <td>{signer.email}</td>
      </tr>}
    />
  </Section>)
}

export default DocumentReview;