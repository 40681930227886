import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Page, Section } from '../../layout';
import { Button, Icon } from '../../components';

const TemplateSent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location

  // useEffect(() => {
  //   if (!state) {
  //     navigate('/dashboard/documents')
  //   }
  // }, [state])

  return (
    <Page className="page-document-sent">
      <Section>
        <Icon id="icon-template_check" size="188" className="mb-4" />
        <h1 className="h1">
          Template: <b>{state?.name}</b> criado com sucesso!
        </h1>
        <Section.Opening>
          Comece agora a utilizar este template ou volte para a 
          página inicial da Assinafy
        </Section.Opening>
        
        <div className="btn-group">
          <Button as={Link} to={`/dashboard/templates/${state?.id}/use`} variant="primary">
            <Icon id="icon-template_add" className="me-1" size="18" />
            UTILIZAR TEMPLATE
          </Button>
          <Button as={Link} to={'/dashboard/templates'} variant="outline">
            VER TODOS OS TEMPLATES
          </Button>
        </div>
      </Section>
    </Page>
  )
}

export default TemplateSent;