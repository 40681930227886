import React, {
	useState,
	useEffect,
	forwardRef,
	useRef,
	useCallback
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Api,
	getSession,
	urlWithAccessToken,
	cancelRequest
} from '../../api/Assinafy';
import { signerColors } from '../../utils/SignersTemplate';
import { usePrepareContext } from '../../routes/PrepareRouteTemplate';
import { PrepareNavbar } from '../../layout/Prepare';
import PageCanvas from '../../components/PageCanvas';
import {
	Form,
	Button,
	Input,
	Icon,
	Viewport,
	Tooltip,
	Modal,
	SignerTemplate
} from '../../components';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import iconClose from '../../assets/images/icon-close.svg';

const LIMIT_BOTTON_TO_MOVE = 180;
const CLOSE_ICON_PADDING = 8;

const TemplatePrepare = ({ virtual }) => {
	const [documentContext, , signersContext, setSignersContext] =
		usePrepareContext();
	const [pages, setPages] = useState([]);
	const [signersUsed, setSignersUsed] = useState([]);
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [modalNewShow, setModalNewShow] = useState(false);
	const [modalRemove, setModalRemove] = useState();
	const { id: documentId } = useParams();
	const navigate = useNavigate();

	const handleSubmit = useCallback(
		(event) => {
			event.preventDefault();
			navigate(`/dashboard/templates/${documentId}/review`);
		},
		[documentId, navigate]
	);

	useEffect(() => {
		if (!window.pageCanvas) {
			window.pageCanvas = [];
		}

		if (!pages.length && documentContext.pages) {
			setPages(() => {
				return documentContext.pages
					.sort((a, b) => a.number - b.number)
					.map((pageInfo) => {
						const currentAccount = getSession().accounts[0];
						pageInfo.download_url = `https://api.funny-chatterjee.stage.assinafy.com.br/v1/accounts/${currentAccount.id}/templates/${documentId}/pages/${pageInfo.id}/download`;
						pageInfo.pageUrl = urlWithAccessToken(pageInfo.download_url);
						return (
							<PageCanvas
								key={pageInfo.id}
								{...pageInfo}
								totalPages={documentContext.pages.length}
							/>
						);
					});
			});
		}

		return () => {
			window.pageCanvas = [];
		};
	}, [documentContext, pages]);

	useEffect(() => {
		if (!virtual) {
			// Filtra apenas os signatários que não são CopyReceiver
			const requiredSigners = signersContext.filter(
				(signer) => signer.assignment_type !== 'CopyReceiver'
			);
	
			// Verifica se todos os signatários obrigatórios (não CopyReceiver) possuem uma ação no documento
			setSubmitDisabled(signersUsed.length !== requiredSigners.length);
		} else {
			setSubmitDisabled(false);
		}
	}, [signersUsed, signersContext, virtual]);
	

	return (
		<>
			<Form
				id="form-document-prepare"
				className="form-prepare"
				onSubmit={handleSubmit}
			>
				<PrepareNavbar
					document={documentContext}
					step="prepare"
					submitDisabled={submitDisabled}
					text="Revisão e conclusão"
					url="templates"
				/>

				{!virtual && (
					<Aside
						documentId={documentId}
						signersUsed={signersUsed}
						setSignersUsed={setSignersUsed}
						setModalNewShow={setModalNewShow}
						setModalRemove={setModalRemove}
					/>
				)}
				<Viewport>
					<div className="viewport-content">{pages}</div>
				</Viewport>
			</Form>
			<Modal.Alert
				show={!!modalRemove}
				variant="danger"
				title="Deseja realmente remover o signatário deste documento?"
				description={modalRemove?.description}
				onClose={() => setModalRemove()}
			>
				<Modal.Footer>
					<Button
						variant="light"
						submit
						onClick={() => setModalRemove()}
					>
						CANCELAR
					</Button>
					<Button
						variant="danger"
						submit
						onClick={modalRemove?.onConfirm}
					>
						<Icon id="icon-trash" className="me-1" size="16" />
						REMOVER
					</Button>
				</Modal.Footer>
			</Modal.Alert>
		</>
	);
};

const Aside = forwardRef(
	({ signersUsed, setSignersUsed, setModalNewShow, setModalRemove }, ref) => {
		const [
			documentContext,
			,
			signersContext,
			setSignersContext,
			fieldsContext,
			setFieldsContext
		] = usePrepareContext();
		const [signerSelected, setSignerSelected] = useState({});
		const [fieldTypes, setFieldTypes] = useState([]);
		const [color, setColor] = useState('transparent');
		const [tooltipShow, setTooltipShow] = useState(false);
		const accountId = getSession().accounts[0].id;
		const cancelToken = cancelRequest();
		const dropdownRef = useRef();

		const fetchFieldsContext = (event, setSignersUsed) => {
			if (event === 'added' || event === 'removed') {
				setSignersUsed([]);
			}

			return window.pageCanvas.map((canvas) => {
				return {
					page: canvas.page,
					fields: canvas
						.getObjects()
						.filter((v) => v.data)
						.map((obj) => {
							const { font, backgroundColor, signer, fieldType } =
								obj.data;

							if (event === 'added' || event === 'removed') {
								setSignersUsed((prevState) => {
									if (
										!prevState.find(
											(id) => id === signer.id
										)
									) {
										return [...prevState, signer.id];
									}

									return [...prevState];
								});
							}

							return {
								id: obj.id,
								signer,
								backgroundColor,
								fieldType,
								displaySettings: {
									...obj.getBoundingRect(),
									...font
								}
							};
						})
				};
			});
		};

		const closeIcon = (textBox, iconCallback) => {
			const fabric = window.fabric;

			return fabric.loadSVGFromURL(
				`${iconClose}`,
				function (objects, options) {
					const group = fabric.util.groupSVGElements(objects);
					textBox.closeIcon = group;
					textBox.closeIcon.left =
						textBox.left +
						textBox.width +
						textBox.padding +
						CLOSE_ICON_PADDING;
					textBox.closeIcon.top = textBox.top;
					textBox.closeIcon.hoverCursor = 'pointer';
					iconCallback(group);
				}
			);
		};

		const findFieldInPage = (fieldId) => {
			return window.pageCanvas.find((pc) =>
				pc.getObjects().find((obj) => obj.id === fieldId)
			);
		};

		const newTextBox = (currentPageCanvas, itemType, attributes = {}) => {
			let name = signerSelected.name;
			if (attributes?.data) {
				name = attributes.data.signer.name;
			}

			const fabric = window.fabric;
			const { width, height, top } =
				currentPageCanvas.lowerCanvasEl.getBoundingClientRect();
			const textBox = new fabric.Textbox(`${itemType.name} - ${name}`, {
				left: width / 2 + 30,
				top: height / 2 - top + 150,
				fontFamily: 'Arial',
				id: uuid(),
				padding: 10,
				fontSize: 22,
				width: 400,
				textAlign: 'center',
				height: 30,
				color: color.color,
				backgroundColor: color.bgColor,
				objectCaching: false,
				lockRotation: true,
				lockScalingY: true,
				lockScalingFlip: true,
				...attributes
			});

			textBox.data = {
				fieldType: itemType,
				signer: signerSelected,
				backgroundColor: textBox.backgroundColor,
				font: {
					fontFamily: textBox.fontFamily,
					fontSize: textBox.fontSize
				},
				...attributes.data
			};

			textBox.closeIcon = closeIcon(textBox, (icon) => {
				currentPageCanvas.add(icon);
				icon.on('mousedown', function (e) {
					//TODO: display confirmation dialog first
					removeTextBox(currentPageCanvas, textBox);
					currentPageCanvas.requestRenderAll();
				});
			});

			textBox.onMoving = (e) => {
				e.target.closeIcon.selectable = false;
				e.target.closeIcon.left =
					e.target.left +
					e.target.padding +
					e.target.width +
					CLOSE_ICON_PADDING;
				e.target.closeIcon.top = e.target.top;
				e.target.closeIcon.setCoords();
				e.target.setCoords();
			};

			textBox.on('mouseup', (e) => currentPageCanvas.requestRenderAll());

			return textBox;
		};

		const moveTextBox = (
			textBox,
			fromPageCanvas,
			toPageCanvas,
			direction = 'next'
		) => {
			removeTextBox(fromPageCanvas, textBox);

			if (findFieldInPage(textBox.id)) {
				return;
			}

			const directionY = {
				previous:
					toPageCanvas.height - textBox.height - textBox.padding - 10,
				next: textBox.padding + 10
			};
			const newObjAttributes = {
				top: directionY[direction],
				left: textBox.left,
				id: textBox.id,
				backgroundColor: textBox.backgroundColor,
				data: textBox.data
			};

			addFieldToCanvas(
				toPageCanvas,
				textBox.data.fieldType,
				newObjAttributes
			);
		};

		const removeTextBox = (canvas, textBox) => {
			canvas.remove(textBox.closeIcon);
			canvas.remove(textBox);
		};

		const addFieldToCanvas = (
			currentPageCanvas,
			itemType,
			attributes = {}
		) => {
			const textBox = newTextBox(currentPageCanvas, itemType, attributes);
			currentPageCanvas.add(textBox);
			currentPageCanvas.on('mouseup', (e) => e.target.onMoving(e));

			currentPageCanvas.on('object:modified', function (event) {
				event.target.onMoving(event);
			});

			currentPageCanvas.on('object:moving', function (event) {
				const currentTextBox = event.target;
				if (!currentTextBox) return;

				const pageNumber = currentPageCanvas.page.number;

				event.target.onMoving(event);

				if (currentTextBox.top < 0 && pageNumber === 1) {
					currentTextBox.top = 10;
				} else if (
					currentTextBox.top + currentTextBox.height >
						currentPageCanvas.height &&
					pageNumber === window.pageCanvas.length
				) {
					currentTextBox.top =
						currentPageCanvas.height - currentTextBox.height - 10;
				}

				if (currentTextBox.left < 0) {
					currentTextBox.left = 10;
				} else if (
					currentTextBox.left + currentTextBox.width >
					currentPageCanvas.width
				) {
					currentTextBox.left =
						currentPageCanvas.width - currentTextBox.width - 10;
				}

				if (currentTextBox.top > currentPageCanvas.height) {
					const nextPageCanvas = getNextCanvasPage(pageNumber);
					if (!nextPageCanvas) return;

					moveTextBox(
						currentTextBox,
						currentPageCanvas,
						nextPageCanvas
					);
				}

				if (parseInt(currentTextBox.top + currentTextBox.height) < 0) {
					const prevPageCanvas = getPreviousCanvasPage(pageNumber);
					if (!prevPageCanvas) return;

					moveTextBox(
						currentTextBox,
						currentPageCanvas,
						prevPageCanvas,
						'previous'
					);
				}
			});

			const collisions = [];
			const objects = currentPageCanvas
				.getObjects()
				.filter((v) => v.data);
			let objectsI = -1;

			while (++objectsI < objects.length) {
				const obj = objects[objectsI];
				if (obj.id !== textBox.id) {
					collisions.push(obj);
				}
			}

			Promise.all(collisions).then((results) =>
				Promise.all(
					results.map(({ height }) => (textBox.top += height + 20))
				)
			);

			currentPageCanvas.setActiveObject(textBox);
			currentPageCanvas.requestRenderAll();

			return textBox;
		};

    const addFieldToDocument = (type) => {
      const pageElements = document.getElementsByClassName('lower-canvas')
      const pageNumber = parseInt(Array.from(pageElements).find((page) => {
        const { top, bottom } = page.getBoundingClientRect()
        return top < window.innerHeight && bottom >= LIMIT_BOTTON_TO_MOVE;
      }).getAttribute('number'))
      const pageCanvas = window.pageCanvas[pageNumber - 1]
  
      addFieldToCanvas(pageCanvas, type)
    }
		const getNextCanvasPage = (currentNumber) => {
			return window.pageCanvas.find(
				(canvas) => canvas.page.number === currentNumber + 1
			);
		};

		const getPreviousCanvasPage = (currentNumber) => {
			return window.pageCanvas.find(
				(canvas) => canvas.page.number === currentNumber - 1
			);
		};

		const handleChangeSigner = (signer, i) => {
			if (!signer) return;

			const color = signerColors[i] || signerColors[0];
			setColor(color);
			document.documentElement.style.setProperty(
				'--action-bg-color',
				color.bgColor
			);
			document.documentElement.style.setProperty(
				'--action-color',
				color.color
			);

			setSignerSelected(signer);
			if (dropdownRef.current) {
				dropdownRef.current.close();
			}
		};

		const signerRemove = ({ id, name }) => {
			const fieldBySigner = fieldsContext
				.map((p) => p.fields.find((f) => f.signer.id === id))
				.filter((r) => r !== undefined);

			if (fieldBySigner.length) {
				return window.alert(
					'O signatário não pôde ser removido.\nRemove os campos relacionados ao signatário e tente novamente.'
				);
			} else {
				setModalRemove({
					// description: `Você está removendo o signatário ${name} do documento ${documentContext.name}.`,
					onConfirm: () => {
						/* TODO: selecionar proximo signer ao remover signer selecionado */
						if (id !== signerSelected.id) {
							setSignersContext(
								signersContext.filter((s) => s.id !== id)
							);
						}
						setModalRemove();
					}
				});
			}
		};

		const tooltipMouseEnter = () => {
			setTooltipShow(true);
		};

		const tooltipMouseLeave = () => {
			setTooltipShow(false);
		};

		const fieldTypeRender = (field, i) => {
			return (
				<Form.Check key={i}>
					<Icon id={`icon-${field.type}`} size="20" />
					<Input.Radio
						key={field.id}
						defaultChecked={i === 0}
						onClick={() => {
							addFieldToDocument(field);
						}}
					/>
					{field.name}
				</Form.Check>
			);
		};

		useEffect(() => {
			Api('field/index')(accountId, {
				...cancelToken.config,
				params: {
					include_standard: true
				}
			}).then(({ status, data: { data } }) => {
				if (status === 200) {
					setFieldTypes(data);
				}
			});

			return () => {
				cancelToken.cancel();
			};
		}, [accountId]);

		useEffect(() => {
			if (!window.pageCanvas) {
				return;
			}

			window.pageCanvas.forEach((pageCanvas) => {
				pageCanvas.on('object:added', () =>
					setFieldsContext(
						fetchFieldsContext('added', setSignersUsed)
					)
				);
				pageCanvas.on('object:removed', () =>
					setFieldsContext(
						fetchFieldsContext('removed', setSignersUsed)
					)
				);
				pageCanvas.on('object:modified', () =>
					setFieldsContext(
						fetchFieldsContext('modified', setSignersUsed)
					)
				);
			});

			fieldsContext.map(({ page, fields }) =>
				fields.map((field) => {
					const canvas = window.pageCanvas[page.number - 1];
					const {
						id,
						backgroundColor,
						fieldType,
						signer,
						displaySettings: {
							top,
							left,
							fontFamily,
							fontSize,
							width
						}
					} = field;

					const newObjAttributes = {
						id,
						top,
						left,
						width,
						backgroundColor,
						data: {
							fieldType,
							signer,
							backgroundColor,
							font: { fontFamily, fontSize }
						}
					};

					if (canvas && !findFieldInPage(id)) {
						return addFieldToCanvas(
							canvas,
							fieldType,
							newObjAttributes
						);
					}

					return false;
				})
			);

			// eslint-disable-next-line
		}, [window.pageCanvas]);

		useEffect(() => {
			handleChangeSigner(signersContext[0]);
		}, [signersContext]);

		return (
			<aside className="aside">
				<Form.Group className="group-signers">
					<Form.Label>Signatários</Form.Label>

					{!signersContext.length ? (
						<Button
							variant="info"
							onClick={() => setModalNewShow(true)}
						>
							<Icon id="icon-add" className="me-1" size="18" />
							ADICIONAR CONTATO
						</Button>
					) : (
						<>
							<SignerTemplate.Dropdown
								ref={dropdownRef}
								active={signerSelected}
								colors={
									signerColors
								} /* TODO: passar para context??? */
								items={signersContext}
								onChangeItem={handleChangeSigner}
								onRemoveItem={signerRemove}
								onClickNew={() => setModalNewShow(true)}
							/>
							<Form.Text>
								<b>
									{signersContext.length - signersUsed.length}
								</b>{' '}
								signatários faltando
								<span
									className="ms-1"
									onMouseEnter={tooltipMouseEnter}
									onMouseLeave={tooltipMouseLeave}
								>
									<Icon
										id="icon-help"
										size="16"
										color="#8C97A0"
									/>
									<Tooltip show={tooltipShow}>
										Para concluir esse documento
										<br />
										todos os signatários associados
										<br />
										devem conter ao menos uma ação
										<br />
										no documento.
									</Tooltip>
								</span>
							</Form.Text>
						</>
					)}
				</Form.Group>

				<Form.Group className="group-fields">
					<Form.Label>Ações</Form.Label>

					<div className="form-subgroup">
						{fieldTypes
							.filter((f) => f.is_standard)
							.map(fieldTypeRender)}
					</div>
					<div className="form-subgroup">
						{fieldTypes
							.filter((f) => !f.is_standard)
							.map(fieldTypeRender)}
					</div>
				</Form.Group>
			</aside>
		);
	}
);

export default TemplatePrepare;
