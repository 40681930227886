import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Api, cancelRequest } from '../../api/Assinafy'
import { Alert, Icon, Dropzone, Button } from '../../components'
import { Dashboard } from '../../layout'
import DocumentsList from '../Documents/DocumentsList'
import { delay } from 'lodash';

const Documents = () => {
  const [documents, setDocuments] = useState()
  const [errors, setErrors] = useState([])
  const [stateAlert, setStateAlert] = useState(false)
  const [showDocuments, setShowDocuments] = useState(false)
  const [dropzoneKey, setDropzoneKey] = useState(0)
  const [retries, setRetries] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const cancelToken = cancelRequest()
  const dropzoneRef = useRef()

  const uploadOpen = (documentId) => {
    navigate(`/dashboard/documents/${documentId}/view`)
  }

  const getDocuments = () => {
    Api('document/index')({
      ...cancelToken.config,
      params: {
        'per-page': 5
      }
    })
      .then(({ status, data }) => {
        if (status === 200) {
          setDocuments(data.data)
          dropzoneRef.current.removeAll(() => setDropzoneKey(dropzoneKey + 1))
        }
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
        }
      })
  }

  useEffect(() => {
    getDocuments()

    return () => {
      cancelToken.cancel()
    }
  }, [])

  useEffect(() => {
    let timerId;

    setShowDocuments(documents && !!documents.length)

    if (documents && documents.length) {
      const hasDocumentProcessing = documents.find((document) => 
        ['uploading', 'uploaded', 'metadata_processing'].indexOf(document.status) > -1
      )
      if (hasDocumentProcessing) {
        timerId = delay(() => {
          getDocuments()
          setRetries(retries + 1)
        }, 1000 * Math.pow(2, retries));
      } else {
        setRetries(0)
      }
    }

    if (documents?.length) {
      const filterFixed = document.querySelector('.dashboard-fixed-bottom')
      window.addEventListener('scroll', (e) => {
        if (window.scrollY > 20) {
          filterFixed.classList.add('scroll-top')
        } else {
          filterFixed.classList.remove('scroll-top')
        }
      })
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [documents])

  useEffect(() => {
    if (state?.alert) {
      setStateAlert({ ...state.alert })

      window.history.replaceState(Object.assign({ ...state }, { alert: {} }), '')
    }
  }, [state])

  return (<Dashboard.Content>
    <Dashboard.Header
      title="Início"
    />

    {stateAlert &&
      <Alert variant={stateAlert.type} onClose={() => setStateAlert(false)}>
        {stateAlert.message}
      </Alert>
    }

    <Alert show={errors.length} variant="warning" onClose={() => setErrors([])}>
      {errors.map((error) => error)}
    </Alert>

    <Dropzone.Document key={dropzoneKey} ref={dropzoneRef}
      onSubmit={Api('document/upload')}
      onComplete={getDocuments}
      onOpen={uploadOpen}
      size={!showDocuments && 'lg'}
      title="Envie seus arquivos para o sistema"
      description="Arraste e solte arquivos aqui para enviar para o sistema"
      buttonText="ENVIAR"
      buttonSecondary
      icon="dropzone"
      buttonIcon="arrow_upward"
    />

    {showDocuments &&
      <>
        <h2 className="h2">
          Últimos documentos
        </h2>
        <DocumentsList rows={documents} />
        <Button variant="link"
          as={Link}
          to={'/dashboard/documents'}
        >
          <Icon id="icon-add" className="me-1" size="18" />
          VER TODOS
        </Button>
      </>
    }
  </Dashboard.Content>);
};

export default Documents;

