import { v4 as uuid } from 'uuid';

const signerColors = [
  { bgColor: '#D8E5FF', color: '#1452FF' },
  { bgColor: '#EAF6F1', color: '#00832D' },
  { bgColor: '#FDEBDC', color: '#F18122' },
  { bgColor: '#F4D6D8', color: '#BB0109' },
  { bgColor: '#E1F6DE', color: '#45C82F' },
  { bgColor: '#FFEEED', color: '#EA4335' },
  { bgColor: '#FFF2CF', color: '#FFBA00' },
  { bgColor: '#E7DEEE', color: '#662E93' },
  { bgColor: '#D6F4F1', color: '#01BBA5' },
  { bgColor: '#E6EFFB', color: '#004297' },
  { bgColor: '#D6F2FD', color: '#01AEF0' },
  { bgColor: '#FCD6ED', color: '#ED008C' },
  { bgColor: '#E9E2DD', color: '#774C29' },
  { bgColor: '#F4E8D6', color: '#BB7001' }
]

const signerEmpty = (signer = {
  id: null,
  name: '',
  assignment_type: ''
}) => {
  const { id, name, assignment_type } = signer
  return {
    uuid: uuid(),
    ...{ id, name, assignment_type }
  }
}

export {
  signerColors,
  signerEmpty
}