import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Api, cancelRequest } from '../../api/Assinafy';
import { signerColors } from '../../utils/SignersTemplate';
import { usePrepareContext } from '../../routes/PrepareRoute';
import { PrepareNavbar } from '../../layout/Prepare';
import { Dashboard, Scroll, Section } from '../../layout';
import {
	Form,
	Table,
	Avatar,
	Textarea,
	Button,
	Icon,
	Input,
	Alert,
	SignerDocTemplate
} from '../../components';
import { signerEmpty } from '../../utils/SignersTemplate';

const TemplateUse = ({ virtual }) => {
	const [
		documentContext,
		setDocumentContext,
		signersContext, 
		setSignersContext,
		fieldsContext
	] = usePrepareContext();
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState([]);
	const [formData, setFormData] = useState({});
	const [showExpiration, setShowExpiration] = useState(false);
	const { id: documentId } = useParams();
	const navigate = useNavigate();
	const cancelToken = cancelRequest();
	const datePickerRef = useRef();
	const [newSigners, setNewSigners] = useState([])
	const [formValidation, setFormValidation] = React.useState([])

	const addSignersContext = ({ id, name, assignment_type }) => {
		setSignersContext((prevState) => {
		  if (prevState.find((signer) => signer.name === name)) {
			return prevState
		  }
	
		  return [
			...prevState, {
			  id,
			  name,
			  assignment_type
			}
		  ]
		})
	  }

	const addNewSigner = () => setNewSigners([
		...newSigners,
		signerEmpty()
	  ])
	
	  const updateNewSigner = (newSigner, uuid) => {
		setNewSigners(prevSigners =>
		  prevSigners.map(signer => signer.uuid === uuid ? newSigner : signer)
		);
	  }
	
	  const removeNewSigner = (uuid) => {
		const newNewSigners = newSigners.filter(signer => signer.uuid !== uuid)
		if (!newNewSigners.length) {
		  newNewSigners.push(signerEmpty())
		}
		setNewSigners(newNewSigners)
	  }

	const handleSubmit = (event) => {
		event.preventDefault();

		if (formValidation.length > 0) {
		  setFormError(["Há erros no formulário. Corrija-os antes de continuar."]);
		  return;
		}
	  
		setLoading(true);
	  
		const signersData = newSigners.map((signer) => ({
		  role_id: signer.id,
		  email: signer.email,     
		  name: signer.name        
		}));
	  
		const formData = {
		  name: newSigners.name,  
		  message: newSigners.message || '', 
		  editor_fields: fieldsContext.map((field) => ({
			field_id: field.id,
			value: field.value
		  })),
		  signers: signersData 
		};
	  
		Api('document/fromTemplate')(documentId, formData)
		  .then(({ status }) => {
			if (status === 200) {
			  navigate(`/dashboard/templates/${documentId}/review/document`);
			}
		  })
		  .catch(({ response }) => {
			if (response) {
			  setFormError([response.data.message]);
			}
			setLoading(false);
		  });
	};
	  

	const fieldValueChange = (name, value) => {
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleOpenClick = () => {
		setShowExpiration(true);
	};

	const handleCloseClick = () => {
		setShowExpiration(false);
	};

	useEffect(() => {

		if (!newSigners.length && !signersContext.length) {
			addNewSigner()
		}
		
		Api('template/view')(documentId, {
			...cancelToken.config
		}).then(({ status, data }) => {
			if (status === 200) {
				window.pageCanvas = [];
				setDocumentContext(data.data);
				setNewSigners(data.data.roles)
			}
		});

		return () => {
			cancelToken.cancel();
		};
	}, []);

	return (
		<Form
			id="form-document-review"
			className="form-prepare"
			onSubmit={handleSubmit}
		>
			<PrepareNavbar
				document={documentContext}
				step="signers"
				page="use"
				submitLoading={loading}
				text="Revisão e conclusão"
				url="templates"
			/>
			<Scroll>
				<Dashboard.Content>
					<Row>
						<Col md={{ span: 8, offset: 2 }}>
							<Dashboard.Header title={documentContext.name} />

							<Alert
								show={formError.length}
								variant="warning"
								onClose={() => setFormError([])}
							>
								{formError.map((error) => error)}
							</Alert>

							<Section className="section-document">
								<Section.Header
								title="Quem vai assinar ou receber o documento?"
								opening={`Informe agora quem serão as pessoas que terão algum tipo de acesso ou papéis neste documento.`}
								/>

								{newSigners.map((signer, i) => {
								return (<SignerDocTemplate.Add 
									key={signer.id}
									{...signer}
									signers={newSigners}
									onUpdate={updateNewSigner}
									onRemove={removeNewSigner}
									formValidation={formValidation}
									setFormValidation={setFormValidation}
									role={signer}
									bgColor={signerColors[i].color}
								  />
								  )
								})}
							</Section>

							{/* <Section>
								<Section.Header
									title="Informações antes do envio"
									opening="É necessário preencher os campos abaixo antes de enviar o documento"
								/>
								<Form.Group>
									<Input 
										size="lg" name="address" label="Endereço do imóvel" minLength="5"
										onChange={(event) =>
											fieldValueChange(
												event.target.name,
												event.target.value
											)
										}
									/>
								</Form.Group>
								<Form.Group>
									<Input 
										size="lg" name="value_rent" label="Valor do aluguel" minLength="5"
										onChange={(event) =>
											fieldValueChange(
												event.target.name,
												event.target.value
											)
										}
									/>
								</Form.Group>
							</Section> */}


							<Section>
								<Section.Header
									title="Título do documento e mensagem"
									opening="Esta mensagem será enviada a todos que vão receber o documento, e pode ser personalizada a cada utilização deste template"
								/>
								<Form.Group>
									<Input 
										size="lg" name="name" label="Nome do documento" minLength="5"
										onChange={(event) =>
											fieldValueChange(
												event.target.name,
												event.target.value
											)
										}
									/>
								</Form.Group>
								<Form.Group>
									<Textarea
										name="message"
										onChange={(event) =>
											fieldValueChange(
												event.target.name,
												event.target.value
											)
										}
										label="Mensagem"
									/>
								</Form.Group>
							</Section>

							<Section>
							<Section.Header
								title="Validade do documento"
								opening="Deseja colocar uma data de validade para esse documento?"
							/>
							{!showExpiration ?
								<Button variant="link" onClick={handleOpenClick}>
								<Icon id="icon-date" className="me-1" size="18" />
								DEFINIR DATA
								</Button>
								:
								<Form.Group className="group-calendar">
								<Input.Group>
									<Input.Date ref={datePickerRef}
									name="expires_at"
									className="control-datepicker"
									onChange={(value) => fieldValueChange('expires_at', value)}
									label="Data"
									/>
									<Button variant="icon" onClick={handleCloseClick}>
									<Icon id="icon-close" size="24" />
									</Button>\
								</Input.Group>
								</Form.Group>
							}
							</Section>
						</Col>
					</Row>
				</Dashboard.Content>
			</Scroll>
		</Form>
	);
};

const SectionSigners = ({ signers }) => {
	return (
		<Section>
			<Section.Header
				title="Signatários"
				opening="Verifique se você associou todos os papéis de quem vai assinar ou receber uma cópia do documento"
			/>
			<Table
				rows={signers}
				renderRow={(signer, i) => (
					<tr key={i}>
						<td>
							<div className="signer-header">
								<Avatar
									placeholder={
										<Icon
											id="icon-initial"
											className="mt-1"
											size="20"
										/>
									}
									bgColor={signerColors[i].color}
								/>
								{signer.name}
								{signer.assignment_type && (
									<span className="ms-1 text-muted">
										{signer.assignment_type === 'Signer'
											? 'Assina o documento'
											: 'Recebe uma cópia'}
									</span>
								)}
							</div>
						</td>
						<td>{signer.email}</td>
					</tr>
				)}
			/>
		</Section>
	);
};

export default TemplateUse;
