import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import SignaturePad from 'signature_pad';
import { Api } from '../../../api/Assinafy';
import { Row, Col } from 'react-bootstrap';
import { PageLoader, Alert, Modal, Tabs, Form, Input, Button, Icon } from '../../../components';

const fonts = window.WebFontFamilies
const previewFontSize = '30px'

const ModalSignature = forwardRef((props, ref) => {
  const { signer } = props
  const [error, setError] = useState([])
  const [signerName, setSignerName] = useState('')
  const [signerInitial, setSignerInitial] = useState('')
  const [mode, setMode] = useState('view')
  const [tabKey, setTabKey] = useState('style')
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [fontSelected, setFontSelected] = useState(fonts[0])
  const [signature, setSignature] = useState(null)
  const [signaturePad, setSignaturePad] = useState(null)
  const [initial, setInitial] = useState(null)
  const [initialPad, setInitialPad] = useState(null)

  const isCanvasEmpty = (canvas) => {
    const blankCanvas = document.createElement('canvas');
    blankCanvas.width = canvas.width;
    blankCanvas.height = canvas.height;
    return canvas.toDataURL() === blankCanvas.toDataURL();
  }

  const handleUpload = async () => {
    const { accessCode } = signer
    const canvasFns = []
    const blobsFns = []

    setError([])

    if (!isCanvasEmpty(signaturePad.canvas)) {
      canvasFns.push({
        mode: tabKey,
        type: 'signature',
        canvas: signaturePad.canvas
      })
    } else {
      setError(prevState => [...prevState, 'A assinatura não pode estar em branco'])
    }

    if (!isCanvasEmpty(initialPad.canvas)) {
      canvasFns.push({
        mode: tabKey,
        type: 'initial',
        canvas: initialPad.canvas
      })
    } else {
      setError(prevState => [...prevState, 'A rúbrica não pode estar em branco'])
    }

    if (canvasFns.length === 2) {
      setLoading(true)

      await canvasFns.map(async ({ mode, type, canvas }) => {
        blobsFns.push(new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve({ type, blob: blob })
          }, 'image/png', 1.0, type)
        }))
      })

      Promise.all(blobsFns).then((results) => {
        Promise.all(results.map(async ({ type, blob }) => {
          await Api('signer/uploadSignature')(blob, accessCode, type)
            .then(() => {
              if (type == 'signature') {
                readAndSetBlob(blob, setSignature)
              } else {
                readAndSetBlob(blob, setInitial)
              }
            })
        })).then(handleClose)
      })
    }
  }

  const readAndSetBlob = (blob, setter) => {
    var reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setter(reader.result)
    }
  }

  const fetchSignatureAndInitial = () => {
    ['signature', 'initial'].forEach(type => {
      Api('signer/signature')(props.signerAccessCode, type)
        .then(({ data }) => {
          if (type == 'signature') {
            readAndSetBlob(data, setSignature)
          } else {
            readAndSetBlob(data, setInitial)
          }
        })
        .catch(error => {
          setMode('edit')
          setSignature(true)
        });
    })
  }

  const handleChoosenSignature = (fontName) => {
    setFontSelected(fontName)

    window.setTimeout(() => {
      const signatureCanvas = signaturePad.canvas
      signaturePad.clear()

      const signatureContext = signatureCanvas.getContext('2d')
      signatureContext.restore()
      signatureContext.fillStyle = '#000F55'
      signatureContext.font = `${previewFontSize} '${fontName}'`
      signatureContext.fillText(signerName, 16, 35)

      const initialCanvas = initialPad.canvas
      initialPad.clear()

      const initialContext = initialCanvas.getContext('2d')
      initialContext.restore()
      initialContext.fillStyle = '#000F55'
      initialContext.font = `${previewFontSize} '${fontName}'`
      initialContext.fillText(signerInitial, 16, 35)
    })
  }

  const handleTabSelect = (tabKey) => {
    setTabKey(tabKey)

    window.setTimeout(() => {
      const signaturePadElm = document.getElementById(`name-${tabKey}-pad`)
      let signaturePad;
      if (signaturePadElm) {
        signaturePad = new SignaturePad(signaturePadElm)
        signaturePad.penColor = '#000F55'
      }
      setSignaturePad(signaturePad)

      const initialPadElm = document.getElementById(`initial-${tabKey}-pad`)
      let initialPad;
      if (initialPadElm) {
        initialPad = new SignaturePad(initialPadElm)
        initialPad.penColor = '#000F55'
      }
      setInitialPad(initialPad)
    })
  }

  const handleClose = () => {
    setShow(false)
    setMode('view')
    setTabKey('style')
    setLoading(false)
    setError([])

    if (signature === true) {
      setSignature(null)
    }
  }

  useImperativeHandle(ref, () => ({
    show: () => setShow(true)
  }))

  useEffect(() => {
    const fluidCanvas = document.querySelectorAll('canvas.fluid');
    if (fluidCanvas) {
      fluidCanvas.forEach((canvas) => {
        const width = canvas.parentNode.offsetWidth
        canvas.setAttribute('width', width)
      });
    }
  }, [tabKey])

  useEffect(() => {
    if (show) {
      if (!signature) {
        setLoading(true)

        fetchSignatureAndInitial()
      }
    }
  }, [show])

  useEffect(() => {
    if (mode == 'edit') {
      const signerName = signer.full_name.toLowerCase().split(/\s+/).map(v => (v && v[0]) ? v[0].toUpperCase() + v.slice(1) : '')
      setSignerName(signerName.join(' '))

      const signerInitial = signer.full_name.split(/\s+/).map(v => (v && v[0]) ? v[0].toString().toUpperCase() : '')
      setSignerInitial(signerInitial.join(''))

      handleTabSelect(tabKey)
    }
  }, [mode])

  useEffect(() => {
    if (signature) {
      setLoading(false)
    }
  }, [signature])

  if (!show) {
    return (<></>)
  }

  if (!signature) {
    return (
      <PageLoader />
    )
  }

  if (mode == 'view') {
    return (
      <Modal show={show}
        className="modal-signature"
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <Button variant="icon" className="btn-close"
            onClick={handleClose}
          >
            <Icon id="icon-close" size="24" />
          </Button>
          <Modal.Title as={'div'} className="h1">
            Sua assinatura
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {signature && signature !== true &&
            <div>
              <img src={signature} />
            </div>
          }
          <hr/>
          {initial && initial !== true &&
            <div>
              <h2 className="h1">
                Sua rúbrica
              </h2>
              <img src={initial} />
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary"
            onClick={() => setMode('edit')}
            loading={loading}
          >
            EDITAR ASSINATURA
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <Modal show={show}
      className="modal-signature"
      onHide={handleClose}
      centered
    >
      <Modal.Header>
        <Button variant="icon" className="btn-close"
          onClick={handleClose}
        >
          <Icon id="icon-close" size="24" />
        </Button>
        <Modal.Title as={'div'} className="h1">
          Sua assinatura
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert show={error.length} variant="warning" onClose={() => setError([])}>
          {error.map((error) => <div>{error}</div>)}
        </Alert>
        <Row>
          <Col xs="8">
            <Form.Group>
              <Input name="signer_name"
                autoComplete="off"
                autoFocus={true}
                label="Nome completo"
                size="lg"
                defaultValue={signerName}
                onChange={(e) => setSignerName(e.target.value)}
                maxLength="22"
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Input name="initial"
                autoComplete="off"
                label="Rúbrica"
                size="lg"
                maxLength="5"
                defaultValue={signerInitial}
                onChange={(e) => setSignerInitial(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Tabs
          activeKey={tabKey}
          onSelect={handleTabSelect}
        >
          <Tabs.Tab eventKey="style" title="Selecionar estilo">
            Selecione o estilo abaixo para vizualizar:

            <div className="row">
              <div className="col-8">
                <div className="signature-pad read-only">
                  <canvas id="name-style-pad" className="fluid" height="60" />
                </div>
              </div>
              <div className="col-4">
                <div className="signature-pad read-only">
                  <canvas id="initial-style-pad" className="fluid" height="60" />
                </div>
              </div>
            </div>

            {signerName && signerName.length > 0 && (
              <div className="signature-list">
                {fonts.map(fname => {
                  return (
                    <label key={`signature-${fname}`} className="signature-item"
                      onClick={() => handleChoosenSignature(`${fname}`)}
                    >
                      <Input.Radio name="signature" value="1" />
                      <div className="signature-item-body" >
                        <Row style={{ fontFamily: fname }}>
                          <Col lg="8">
                            {signerName}
                          </Col>
                          <Col lg="3">
                            {signerInitial}
                          </Col>
                        </Row>
                      </div>
                    </label>
                  )
                })}
              </div>
            )}
          </Tabs.Tab>
          <Tabs.Tab eventKey="custom" title="Personalizar">
            <div className="signature-pad-wrapper">
              Desenhe abaixo sua assinatura
              <div className="signature-pad">
                <canvas id="name-custom-pad" className="fluid" height="160" />
              </div>
              <Button
                variant="link"
                onClick={e => signaturePad.clear()}
              >
                <Icon id="icon-trash" className="me-1" size="16" />
                LIMPAR
              </Button>
            </div>
            <div className="signature-pad-wrapper">
              Desenhe abaixo sua rúbrica
              <div className="signature-pad">
                <canvas id="initial-custom-pad" className="fluid" height="160" />
              </div>
              <Button
                variant="link"
                onClick={e => initialPad.clear()}
              >
                <Icon id="icon-trash" className="me-1" size="16" />
                LIMPAR
              </Button>
            </div>
          </Tabs.Tab>
          {/*
          <Tabs.Tab eventKey="upload" title="Upload">
            <Image width='400px' height='300px' id='image-preview' thumbnail />
            <Form.Group>
              <Input.File onChange={e => {
                const [file] = e.target.files
                if (file) {
                  const preview = document.getElementById('image-preview')
                  setSelectImageBlob(file)
                  preview.src = URL.createObjectURL(file)
                }
              }} />
            </Form.Group>
          </Tabs.Tab>
          */ }
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info"
          onClick={handleClose}
        >
          CANCELAR
        </Button>
        <Button variant="primary"
          onClick={handleUpload}
          loading={loading}
        >
          <Icon id="icon-initial" className="me-1" size="16" />
          ADOTAR ASSINATURA
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default ModalSignature;