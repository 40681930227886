import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { usePrepareContext } from '../../routes/PrepareRouteTemplate';
import { Api } from '../../api/Assinafy'
import { Dashboard, Scroll, Section } from '../../layout';
import { PrepareNavbar } from '../../layout/Prepare';
import { Form, Button, Icon, Alert, SignerTemplate } from '../../components';
import { signerEmpty } from '../../utils/SignersTemplate';

import './styles.scss';

const TemplateSigners = ({ virtual }) => {
  const [validated, setValidated] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [formValidation, setFormValidation] = React.useState([])
  const [formError, setFormError] = React.useState([])
  const [documentContext, setDocumentContext, signersContext, setSignersContext] = usePrepareContext()
  const [newSigners, setNewSigners] = useState([])
  const navigate = useNavigate()
  const { id: documentId } = useParams()

  const addSignersContext = ({ id, name, assignment_type }) => {
    setSignersContext((prevState) => {
      if (prevState.find((signer) => signer.name === name)) {
        return prevState
      }

      return [
        ...prevState, {
          id,
          name,
          assignment_type
        }
      ]
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
  
    if (event.target.checkValidity()) {
      setLoading(true);
  
      const existingSigners = [];
      const newSignersToCreate = [];
  
      newSigners.forEach((signer) => {
        const existingSigner = signersContext.find(s => s.id === signer.id || s.name === signer.name);
        if (existingSigner) {
          existingSigners.push(signer);
        } else {
          newSignersToCreate.push(signer); 
        }
      });
  
      const updatePromises = existingSigners.map(async (signer) => {
        const { id, name, assignment_type } = signer;
        return Api('templateRole/update')(documentId, id, { name, assignment_type })
          .then(({ status, data: { data } }) => {
            if (status === 200) {
              addSignersContext({ id: data.id, name, assignment_type });
            }
          })
          .catch(({ response }) => {
            if (response) {
              setFormError((prevErrors) => [...prevErrors, response.data.message]);
            }
          });
      });
  
      const createPromises = newSignersToCreate.map(async (signer) => {
        const { name, assignment_type } = signer;
        return Api('templateRole/create')(documentId, { name, assignment_type })
          .then(({ status, data: { data } }) => {
            if (status === 200) {
              addSignersContext({ id: data.id, name, assignment_type });
            }
          })
          .catch(({ response }) => {
            if (response) {
              setFormError((prevErrors) => [...prevErrors, response.data.message]);
            }
          });
      });
  
      Promise.all([...updatePromises, ...createPromises])
        .then(() => {
          navigate(`/dashboard/templates/${documentId}/prepare`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormValidation([]);
      Array.from(event.target.elements).forEach((element) => {
        if (element.name && element.dataset) {
          setFormValidation((prevState) => [
            ...prevState,
            { uuid: element.dataset.uuid, name: element.name, message: element.validationMessage },
          ]);
        }
      });
    }
  };  
  
  
  const addNewSigner = () => setNewSigners([
    ...newSigners,
    signerEmpty()
  ])

  const updateNewSigner = (newSigner, uuid) => {
    setNewSigners(prevSigners =>
      prevSigners.map(signer => signer.uuid === uuid ? newSigner : signer)
    );
  }

  const removeNewSigner = (uuid) => {
    const newNewSigners = newSigners.filter(signer => signer.uuid !== uuid)
    if (!newNewSigners.length || newNewSigners.length === 1) {
      newNewSigners.push(signerEmpty())
    }
    setNewSigners(newNewSigners)

    Api('templateRole/delete')(documentId, uuid)
      .then(({ status }) => {
        if (status === 200) {
          setNewSigners(newSigners.filter(signer => signer.id !== uuid))
        }
      })
  }

  useEffect(() => {
    if (!newSigners.length || newSigners.length === 1) {
      addNewSigner()
    }

    Api('template/view')(documentId)
      .then(({ status, data }) => {
        if (status === 200) {
          const documentData = data.data;
          setDocumentContext(documentData);

          const existingSigners = documentData.roles.filter(role => role.name !== 'Editor');
          setSignersContext(existingSigners);
          setNewSigners(existingSigners);
        }
      })
      .catch(() => {
        navigate('/dashboard/templates')
      })
  }, [])

  useEffect(() => {
    signersContext.map(signer => setNewSigners(prevState => [...prevState, signerEmpty(signer)]))
  }, [])

  return (<>
    <Form id="form-document-signers-new"
      className="form-prepare"
      onSubmit={handleSubmit}
    >
      <PrepareNavbar
        document={documentContext}
        step="signers"
        submitLoading={loading}
        text="Revisão e conclusão"
        url="templates"
      />
      <Scroll>
        <Dashboard.Content>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Dashboard.Header
                title="Signatários"
              />
              <Section className="section-document">
                <Section.Header
                  title="Quem vai assinar ou receber o documento?"
                  opening={`Informe abaixo os papéis de quem vai assinar o documento ou apenas receber uma cópia após todos assinarem`}
                  example={`Exemplo: locador, locatário, testemunha`}
                />

                <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
                  {formError.map((error) => error)}
                </Alert>

                {newSigners.map((signer, i) => {
                  return (<SignerTemplate.Add key={signer.uuid}
                    {...signer}
                    signers={newSigners}
                    onUpdate={updateNewSigner}
                    onRemove={removeNewSigner}
                    formValidation={formValidation}
                    setFormValidation={setFormValidation}
                  />)
                })}

                <Form.Footer horizontal>
                  <Button variant="primary"
                    onClick={addNewSigner}
                  >
                    <Icon id="icon-add" className="me-1" size="18" />
                    ADICIONAR MAIS
                  </Button>
                </Form.Footer>
              </Section>
            </Col>
          </Row>
        </Dashboard.Content>
      </Scroll>
    </Form>
  </>);
}

export default TemplateSigners;
