import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SessionContext } from '../../routes/DashboardRoute';
import Scroll from '../Scroll';
import { Icon } from '../../components';
import DropdownUser from '../../components/Dropdown/DropdownUser';
import classNames from 'classnames';

import logo from '../../assets/images/assinafy-logo.svg';
import './styles.scss';

const Dashboard = ({ children }) => {
  const [navbarShown, setNavbarShown] = useState(false)
  const [session] = useContext(SessionContext)

  const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent)

  // const handleNavbarShown = () => {
  //   setNavbarShown(!navbarShown)
  // }

  const handleNavItemClick = () => {
    setNavbarShown(false)
  }

  useEffect(() => {
    if (isMobile()) {
      document.body.classList.add('is-mobile');
    } else {
      document.body.classList.remove('is-mobile');
    }
  }, [])

  return (<div className="dashboard">
    <header className="dashboard-header">
      <Link to={'/dashboard'} className="logo">
        <img src={logo} height="24" alt="Assinafy logo" />
      </Link>
      <div className={`navbar${navbarShown ? ' show' : ''}`}>
        <ul className="nav">
          <NavItems
            items={[
              {
                to: '/dashboard', children: <>
                  <Icon id="icon-leaderboard" size="24" />
                  Início
                </>
              },
              {
                to: '/dashboard/documents', children: <>
                  <Icon id="icon-description" size="24" />
                  Documentos
                </>
              },
              {
                to: '/dashboard/templates', children: <>
                  <Icon id="icon-template" size="24" />
                  Templates
                </>
              },
              {
                to: '/dashboard/signers', children: <>
                  <Icon id="icon-group" size="24" />
                  Seus contatos
                </>
              },
              {
                to: '/dashboard/workspace', children: <>
                  <Icon id="icon-palette" size="24" />
                  Personalizar
                </>
              }
            ]}
            onClick={handleNavItemClick}
          />
        </ul>
      </div>
    </header>
    <Scroll>
      <DropdownUser
        session={session}
        onClick={handleNavItemClick}
      />
      {children}
    </Scroll>
  </div >);
}

const DashboardContent = (props) => {
  return (<div className="dashboard-content"
    {...props}
  />)
}

const DashboardTitle = ({ as: Component = 'h1', ...props }) => {
  return (
    <Component {...props} className="dashboard-title" />
  )
}

const DashboardOpening = (props) => {
  return (
    <div {...props} className="dashboard-opening" />
  )
}

const DashboardHeader = ({ title, titleAs, opening, ...props }) => {
  const { children, className } = props
  const newClassName = classNames('dashboard-subheader', className)

  return (
    <div {...props} className={newClassName}>
      <div>
        <DashboardTitle as={titleAs}>
          {title}
        </DashboardTitle>
        {opening &&
          <DashboardOpening>
            {opening}
          </DashboardOpening>
        }
      </div>
      {children}
    </div>
  );
}

const DashboardFilter = (props) => {
  return (
    <div {...props} className="dashboard-filter" />
  );
};
const DashboardFixedBottom = (props) => {
  useEffect(() => {
    const filterFixed = document.querySelector('.dashboard-fixed-bottom')
    window.addEventListener('scroll', () => {
      if (window.scrollY > 20) {
        filterFixed.classList.add('scroll-top')
      } else {
        filterFixed.classList.remove('scroll-top')
      }
    })
  }, [])

  return (
    <div {...props} className="dashboard-fixed-bottom" />
  );
};

function NavItems({ items, onClick }) {
  let active;

  items.map(({ to }, i) => {
    if (window.location.pathname.match(to)) {
      active = i
    }
  })

  return (<>
    {items.map((props, i) => {
      const newClassName = classNames('nav-link', active == i ? 'active' : '')

      return (<li key={i} className="nav-item">
        <Link {...props}
          className={newClassName}
          onClick={onClick}
        />
      </li>)
    })}
  </>);
}

Dashboard.Content = DashboardContent
Dashboard.Filter = DashboardFilter
Dashboard.FixedBottom = DashboardFixedBottom
Dashboard.Header = DashboardHeader
Dashboard.Title = DashboardTitle
Dashboard.Opening = DashboardOpening

export default Dashboard