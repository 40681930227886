import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Api, cancelRequest } from '../../api/Assinafy'
import { Avatar, Alert, Button, DataList, Dropdown, EmptyList, Form, Input, Icon, Modal, Spinner, Table, Tabs, Tooltip } from '../../components'
import { signerEmpty, signerColors } from '../../utils/Signers'
import classNames from 'classnames'
import './styles.scss'

const debounce = (fn, bufferInterval) => {
  let timeout;

  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(fn.apply.bind(fn, this, arguments), bufferInterval);
  }
}

const ApiSigner = Api('signer/index')

const getSigners = debounce((value, signers, setNewSigners) => {
  ApiSigner({
    params: {
      'search': value,
      'per-page': 5
    }
  })
    .then(({ status, data: { data } }) => {
      if (status === 200) {
        if (data.length) {
          data.map((signer) => {
            setNewSigners((prevState) => {
              const prevSigners = typeof prevState === 'undefined' ? [] : prevState
    
              if (signers.find((prevSigner) => prevSigner.id === signer.id)) {
                return prevSigners
              }
    
              return [
                ...prevSigners,
                signer
              ]
            })
          })
        } else {
          setNewSigners([])
        }
      }
    })
}, 350)

const Signer = {}

const SignerListItem = (props) => {
  const { full_name, email, onClick } = props

  return (
    <div className="datalist-item" onClick={onClick}>
      <Avatar placeholder={full_name[0] || email[0]} />
      <span className="signer-name">
        {full_name}
      </span>
      <span className="signer-email">
        {email}
      </span>
    </div>
  )
};

const SignerDropdown = forwardRef(({
  items,
  active,
  colors, /* TODO: passar para context??? */
  onChangeItem,
  onRemoveItem,
  onClickNew
}, ref) => {
  return (<Dropdown ref={ref}
    button={Object.keys(active).length &&
      <SignerDropdownItem {...active}
        as="div"
        bgColor={colors[items.findIndex(s => s.id === active.id)].color}
      />
    }
    className="dropdown-signer"
  >
    {items.map((signer, i) => {
      return <Dropdown.Item
        key={i}
        as={'div'}
        className={active && items.email === active.email ? 'active' : ''}
      >
        <SignerDropdownItem {...signer}
          onClick={() => onChangeItem(signer, i)}
          bgColor={colors[i].color}
        />
        {signer.id !== active.id &&
          <Button variant="icon"
            className="btn-remove"
            onClick={() => onRemoveItem(signer)}
          >
            <Icon id="icon-trash" size="16" />
          </Button>
        }
      </Dropdown.Item>
    })}
    <Button variant="link"
      className="btn-signer-add"
      onClick={onClickNew}
    >
      <Icon id="icon-add" className="me-1" size="18" />
      ADICIONAR CONTATO
    </Button>
  </Dropdown>)
});

const SignerDropdownItem = ({ as: Component = 'button', type, ...props }) => {
  const { full_name, email, bgColor, onClick } = props

  if (Component === 'button') {
    type = 'button';
  }

  return (
    <Component className="signer-item"
      type={type}
      onClick={onClick}
    >
      <Avatar placeholder={full_name[0] || email[0]} bgColor={bgColor} />
      <div className="signer-info">
        {full_name}
        <div className="signer-email">
          {email}
        </div>
      </div>
    </Component>
  )
};

const SignerAdd = ({
  signers,
  onUpdate,
  onRemove,
  formValidation,
  setFormValidation,
  role,
  ...props
}) => {
  const { uuid, id, name, email, bgColor } = props;
  const [tooltipShow, setTooltipShow] = useState(false);
  const [newSigner, setNewSigner] = useState({ uuid, id, name, email });
  const [newSigners, setNewSigners] = useState([]);
  const fullnameRef = useRef();
  const emailRef = useRef();
  const datalistNameRef = useRef();
  const datalistEmailRef = useRef();

  const debounceSigners = useCallback(getSigners, []);

  const updateNewSigner = (event) => {
    setNewSigner({
      ...newSigner,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;

    if (value.length > 3) {
      if (target.name === "email") {
        datalistEmailRef.current.show();
      } else {
        datalistNameRef.current.show();
      }

      setNewSigners([]);

      debounceSigners(value, signers, setNewSigners);
    }

    setNewSigner((prevState) => ({
      ...prevState,
      [role || 'default']: {
        ...prevState[role || 'default'],
        [event.target.name]: value,
      },
    }));
  };

  const handleInputFocus = (event) => {
    const target = event.target;

    if (newSigners?.length) {
      if (target.name === "email") {
        datalistEmailRef.current.show();
      } else {
        datalistNameRef.current.show();
      }
    }
  };

  const handleInputBlur = (event) => {
    const target = event.target;
    const fieldUUID = target.dataset.uuid; 
    const fieldName = target.name; 

    const validationFilter = formValidation.filter(
      (error) => !(error.uuid === fieldUUID && error.name === fieldName)
    );
  
    if (target.checkValidity()) {
      setFormValidation(validationFilter);
    } else {
      setFormValidation([
        ...validationFilter,
        {
          uuid: fieldUUID, 
          name: fieldName, 
          message: target.validationMessage, 
        },
      ]);
    }
  
    let wait = 0;
    if (!event.relatedTarget) {
      wait = 250;
    }
    if (target.name === "email") {
      datalistEmailRef.current?.hide(wait);
    } else {
      datalistNameRef.current?.hide(wait);
    }
  };

  const handleItemClick = (item) => {
    const { id, full_name, email } = item;
    const fullnameField = fullnameRef.current;
    const emailField = emailRef.current;

    fullnameField.value = full_name;
    fullnameField.setAttribute("readonly", true);
    emailField.value = email;
    emailField.setAttribute("readonly", true);

    setNewSigner({
      uuid: newSigner.uuid,
      id,
      name,
      email,
    });

    setFormValidation(formValidation.filter((error) => error.uuid !== newSigner.uuid));

    datalistEmailRef.current.hide(0);
    datalistNameRef.current.hide(0);
  };

  const tooltipMouseEnter = () => {
    setTooltipShow(true);
  };

  const tooltipMouseLeave = () => {
    setTooltipShow(false);
  };

  const showInputError = (inputName) => {
    const error = formValidation.find(
      (error) => error.uuid === newSigner.uuid && error.name === inputName
    );
    if (error) {
      return <div className="help-block">{error.message}</div>;
    }
  };

  useEffect(() => {
    onUpdate && onUpdate(newSigner, uuid || id);
  }, [newSigner]);

  return (
    <>
      {role ? (
        <Row className="row-signer" key={role.id}>
          <Col xs="3">
            <div className="signer-info">
              <Avatar placeholder={<Icon id="icon-initial" className="mt-2" size="20" />} bgColor={bgColor} />
              <div className="signer-role">
                <Form.Label>{role.name === "Editor" ? "Preparador do documento" : role.name}</Form.Label> 
                <span>{role.assignment_type === "Signer" ? "Assina o documento" : "Recebe uma cópia"}</span>
              </div>
            </div>
          </Col>
          <Col xs="12" lg>
            <Form.Group>
              <Input.Email
                ref={emailRef}
                name="email"
                autoComplete="off"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                label="E-mail"
                data-uuid={newSigner.id}
                readOnly={false}
                required
              />
              {showInputError("email")}
              <DataList
                ref={datalistEmailRef}
                className="datalist-signer"
                items={newSigners}
                renderItem={(item, i) => (
                  <Signer.ListItem
                    key={i}
                    {...item}
                    onClick={() => handleItemClick(item)}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs="10" lg>
            <Form.Group>
              <Input
                ref={fullnameRef}
                name="name"
                autoComplete="off"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                label="Nome"
                data-uuid={newSigner.id}
                readOnly={false}
                required
              />
              {showInputError("name")}
              <DataList
                ref={datalistNameRef}
                className="datalist-signer"
                items={newSigners}
                renderItem={(item, i) => (
                  <Signer.ListItem
                    key={i}
                    {...item}
                    onClick={() => handleItemClick(item)}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <Row className="row-signer">
          <Col xs="12" lg>
            <Form.Group>
              <Input.Email
                ref={emailRef}
                name="email"
                autoComplete="off"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={email}
                label="E-mail"
                data-uuid={newSigner.uuid}
                readOnly={false}
                required
              />
              {showInputError("email")}
              <DataList
                ref={datalistEmailRef}
                className="datalist-signer"
                items={newSigners}
                renderItem={(item, i) => (
                  <Signer.ListItem
                    key={i}
                    {...item}
                    onClick={() => handleItemClick(item)}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs="10" lg>
            <Form.Group>
              <Input
                ref={fullnameRef}
                name="full_name"
                autoComplete="off"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={name}
                label="Nome"
                data-uuid={newSigner.uuid}
                readOnly={false} 
                required
              />
              {showInputError("full_name")}
              <DataList
                ref={datalistNameRef}
                className="datalist-signer"
                items={newSigners}
                renderItem={(item, i) => (
                  <Signer.ListItem
                    key={i}
                    {...item}
                    onClick={() => handleItemClick(item)}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Button
              variant="icon"
              className="btn-remove"
              onMouseEnter={tooltipMouseEnter}
              onMouseLeave={tooltipMouseLeave}
              onClick={() => onRemove(uuid || id)}
            >
              <Icon id="icon-trash" size="28" />
              <Tooltip show={tooltipShow}>Excluir</Tooltip>
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

Signer.Add = SignerAdd
Signer.ListItem = SignerListItem
Signer.Dropdown = SignerDropdown

export default Signer;