import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Api, urlWithAccessToken, downloadFile } from '../../api/Assinafy'
import { Table, Status, Image, Button, Icon, Dropdown, Tooltip, Spinner, Toast, Modal } from '../../components'
import { statusId, statusTitle } from '../../utils/Documents';
import { dateFormat } from '../../utils/Formats';

import thumbDocument from '../../assets/images/thumb-document.svg';

const TemplatesList = (props) => {
  const [toast, setToast] = useState('')
  const { state } = useLocation()

  useEffect(() => {
    if (state?.toast) {
      setToast(state.toast)
      window.history.replaceState({}, document.title)
    }
  }, [state])

  return (<>
    <Table {...props}
      className="table-bordered table-hover"
      header={[
        { name: 'Nome' },
        { name: 'Criado por' },
        { name: 'Criado em' },
        { name: 'Alteração' },
        { name: '' }
      ]}
      renderRow={(row, i) => <TableItem key={i} {...row}
        setToast={setToast}
      />}
    />
    <Toast show={toast} onClose={() => setToast('')}>
      {toast}
    </Toast>
  </>)
}

const TableItem = ({ setToast, ...props }) => {
  const [tooltipShow, setTooltipShow] = useState(false)
  const { assignment } = props
  const summary = assignment?.summary

  const tooltipMouseEnter = () => {
    setTooltipShow(true)
  }

  const tooltipMouseLeave = () => {
    setTooltipShow(false)
  }

  return (
    <tr className={`document-status-${statusId(props.status)}`}>
      <td>
        <Link
          className="document-header"
          onMouseLeave={tooltipMouseLeave}
          to={`/dashboard/templates/${props.id}/view`}
        >
          <Image
            width="40"
            height="56"
            src={props.artifacts?.thumbnail && urlWithAccessToken(props.artifacts.thumbnail)}
            placeholder={thumbDocument}
          />
          <div className="document-title">{props.name}</div>
          <div className="document-signer d-lg-none">
            <Status id={props.status} />
            {summary ? `(${summary.completed_count}/${summary.signer_count})` : ''}
          </div>
          <div className="document-signer d-none d-lg-block" onMouseEnter={tooltipMouseEnter}>
            {summary ?
              <>
                {summary.completed_count === summary.signer_count ?
                  'Assinaturas completas'
                  :
                  `${summary.completed_count}/${summary.signer_count} assinaturas`
                }
                <Tooltip show={tooltipShow}>
                  {summary.signers.map((signer, i) => {
                    return <div key={i}>
                      <Icon
                        id={`icon-${signer.completed ? 'check' : 'close'}`}
                        size="12"
                      /> - {signer.full_name}
                    </div>
                  })}
                </Tooltip>
              </>
              :
              statusTitle(props.status)
            }
          </div>
        </Link>
      </td>
      <td className="d-none d-sm-table-cell">
        {props.roles[0].name}
      </td>
      <td className="d-none d-lg-table-cell">
        {dateFormat(props.created_at)}
      </td>
      <td className="d-none d-lg-table-cell">
        {dateFormat(props.updated_at)}
      </td>
      <td className="col-action">
        <TableActions
          document={props}
          setToast={setToast}
        />
      </td>
    </tr>
  )
}

const TableActions = ({ document, setToast }) => {
  const documentDelete = Api('template/delete')
  const { id: documentId, name: documentName, status: documentStatus, artifacts, assignment } = document
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const dropdownRef = useRef()

  const handleResendAssignmentAll = (i) => {
    const signers = assignment.summary.signers.filter((s) => !s.completed)

    setLoading(i)

    Promise.all(signers.map(async (signer) => {
      await Api('assignment/resend')(documentId, assignment.id, signer.id)
        .catch(() => {
          setLoading(false)
          dropdownRef.current.close()
        })
    }))
      .then(() => {
        setLoading(false)
        dropdownRef.current.close()
        setToast(`Documento ${documentName} reenviado com sucesso`)
      })
  }

  const downloadDocument = (i, type) => {
    setLoading(i)

    downloadFile(urlWithAccessToken(artifacts[type]))
      .then(() => {
        setLoading(false)
        dropdownRef.current.close()
      })
  }

  const handleDelete = (i) => {
    setLoading(true)

    documentDelete(documentId)
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setLoading(false)
          setModalRemove(false)
          navigate(0, {
            state: { toast: `Documento ${documentName} removido com sucesso` }
          })
        }
      })
      .catch(({ response }) => {
        setLoading(false)
        setModalRemove(false)
      })
  }

  const primaryButtons = {
    use: {
      id: 'use',
      icon: 'icon-template_add',
      text: 'UTILIZAR',
      onClick: () => navigate(`/dashboard/templates/${documentId}/use`)
    },
    edit: {
      id: 'edit',
      icon: 'icon-edit',
      text: 'EDITAR',
      onClick: () => navigate(`/dashboard/templates/${documentId}/signers`)
    }
  }

  const actionsButtons = [
    {
      id: 'view',
      icon: 'icon-visibility',
      text: 'VISUALIZAR',
      onClick: () => navigate(`/dashboard/templates/${documentId}/view`)
    },
    {
      id: 'edit',
      icon: 'icon-edit',
      text: 'EDITAR',
      onClick: () => navigate(`/dashboard/templates/${documentId}/signers`)
    },
    {
      id: 'duplicate',
      icon: 'icon-duplicate',
      text: 'DUPLICAR',
      onClick: () => navigate(`/dashboard/templates/${documentId}/duplicate`)
    },
    {
      icon: 'icon-trash',
      text: 'EXCLUIR',
      onClick: () => setModalRemove(true)
    }
  ]
  const actionsByStatus = (status) => {
    if (status === 'Ready' && document.roles.length > 1) {
      return [actionsButtons[0], actionsButtons[1], actionsButtons[2], actionsButtons[3]];
    } else {
      return [actionsButtons[1], actionsButtons[3]];
    }
  }
  
  const primaryByStatus = (document) => {
    if (document.status === 'Ready' && document.roles.length > 1) {
      return primaryButtons.use; 
    } else {
      return primaryButtons.edit;
    }
  }
  const { id, icon, text, loadingText, onClick } = primaryByStatus(document)

  return (<>
    <div className="table-actions">
      <div className="d-none d-xl-table-cell">
        <Button variant="light"
          className="has-hover"
          as={Button}
          onClick={() => onClick(id)}
          loading={loading === id}
          loadingText={loadingText}
          disabled={statusId(document.status) === 'processing' && true}
        >
          <Icon id={icon} size="18" className="me-1" />
          {text}
        </Button>
      </div>
      <Dropdown
        ref={dropdownRef}
        align="right"
        variant="action"
        button={<Icon id="icon-more_vert" />}
      >
        {actionsByStatus(documentStatus).map(({ id, icon, text, loadingText, onClick }, i) =>
          <Dropdown.Item key={i}
            as={Button}
            onClick={() => onClick(id)}
            loading={loading === id}
            loadingText={loadingText}
          >
            <Icon id={icon} className="me-1" size="20" />
            {text}
          </Dropdown.Item>
        )}
      </Dropdown>
    </div>
    <Modal.Alert show={!!modalRemove}
      align="center"
      variant="danger"
      // icon="icon-trash"
      title="Deseja realmente remover esse template?"
      onClose={() => setModalRemove(false)}
    >
      <Modal.Footer>
        <Button variant="danger" submit
          onClick={handleDelete}
          loading={loading}
        >
          <Icon id="icon-trash" className="me-1" size="16" />
          REMOVER
        </Button>
        <Button variant="info" submit
          onClick={() => setModalRemove(false)}
        >
          NÃO, FECHAR
        </Button>
      </Modal.Footer>
    </Modal.Alert>
  </>)
}

export default TemplatesList;