import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { usePrepareContext } from '../../routes/PrepareRoute';
import { Api } from '../../api/Assinafy'
import { Dashboard, Scroll, Section } from '../../layout';
import { PrepareNavbar } from '../../layout/Prepare';
import { Form, Button, Icon, Alert, Signer } from '../../components';
import { signerEmpty } from '../../utils/Signers';

import './styles.scss';

const DocumentSigners = ({ virtual }) => {
  const [validated, setValidated] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [formValidation, setFormValidation] = React.useState([])
  const [formError, setFormError] = React.useState([])
  const [documentContext, setDocumentContext, signersContext, setSignersContext] = usePrepareContext()
  const [newSigners, setNewSigners] = useState([])
  const [showModalSignerList, setShowModalSignerList] = useState(false)
  const navigate = useNavigate()
  const { id: documentId } = useParams()

  const addSignersContext = ({ id, full_name, email }) => {
    setSignersContext((prevState) => {
      if (prevState.find((signer) => signer.email === email)) {
        return prevState
      }

      return [
        ...prevState, {
          id,
          full_name,
          email
        }
      ]
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let registerNewSigners = []

    setValidated(true)

    if (event.target.checkValidity()) {
      setLoading(true)

      Promise.all(newSigners.map(async ({ id, full_name, email }) => {
        if (!id) {
          await Api('signer/index')({
            params: {
              'search': email
            }
          })
            .then(({ status, data: { data } }) => {
              if (status === 200) {
                if (data.length) {
                  addSignersContext({ id: data[0].id, full_name, email })
                } else {
                  registerNewSigners.push({
                    full_name,
                    email
                  })
                }
              }
            })
        } else {
          await addSignersContext({ id, full_name, email })
        }
      }))
        .then(() => {
          Promise.all(registerNewSigners.map(async ({ full_name, email }) => {
            await Api('signer/create')({ full_name, email })
              .then(({ status, data: { data } }) => {
                if (status === 200) {
                  addSignersContext({ id: data.id, full_name, email })
                }
              })
              .catch(({ response }) => {
                if (response) {
                  setFormError([response.data.message])
                }

                setLoading(false)
              })
          }))
            .then(() => {
              navigate(`/dashboard/documents/${documentId}${virtual ? '/virtual' : ''}/prepare`)
            })
        })
    } else {
      setFormValidation([])
      Array.from(event.target.elements).map((element) => {
        if (element.name && element.dataset) {
          setFormValidation((prevState) => [
            ...prevState,
            { uuid: element.dataset.uuid, name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const addNewSigner = () => setNewSigners([
    ...newSigners,
    signerEmpty()
  ])

  const updateNewSigner = (newSigner, uuid) => {
    setNewSigners(newSigners.map(signer => signer.uuid === uuid ? newSigner : signer));
  }

  const removeNewSigner = (uuid) => {
    const newNewSigners = newSigners.filter(signer => signer.uuid !== uuid)
    if (!newNewSigners.length) {
      newNewSigners.push(signerEmpty())
    }
    setNewSigners(newNewSigners)
  }

  useEffect(() => {
    if (!newSigners.length && !signersContext.length) {
      addNewSigner()
    }

    Api('document/view')(documentId)
      .then(({ status, data }) => {
        if (status === 200) {
          setDocumentContext(data.data)
        }
      })
      .catch(() => {
        navigate('/dashboard/documents')
      })
  }, [])

  useEffect(() => {
    signersContext.map(signer => setNewSigners(prevState => [...prevState, signerEmpty(signer)]))
  }, [])

  return (<>
    <Form id="form-document-signers-new"
      className="form-prepare"
      onSubmit={handleSubmit}
    >
      <PrepareNavbar
        document={documentContext}
        step="signers"
        submitLoading={loading}
        text="Revisar e enviar"
        url="documents"
      />
      <Scroll>
        <Dashboard.Content>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Dashboard.Header
                title="Signatários"
              />
              <Section className="section-document">
                <Section.Header
                  title="Quem vai assinar o documento?"
                  opening={`Informe abaixo as pessoas que devem assinar o documento contrato de ${documentContext.name}`}
                />

                <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
                  {formError.map((error) => error)}
                </Alert>

                {newSigners.map((signer, i) => {
                  return (<Signer.Add key={signer.uuid}
                    {...signer}
                    signers={newSigners}
                    onUpdate={updateNewSigner}
                    onRemove={removeNewSigner}
                    formValidation={formValidation}
                    setFormValidation={setFormValidation}
                  />)
                })}

                <Form.Footer horizontal>
                  <Button variant="primary"
                    onClick={addNewSigner}
                  >
                    <Icon id="icon-add" className="me-1" size="18" />
                    ADICIONAR MAIS
                  </Button>
                  <Button variant="info"
                    onClick={() => setShowModalSignerList(true)}
                  >
                    <Icon id="icon-group" />
                    VER CONTATOS
                  </Button>
                </Form.Footer>
              </Section>
            </Col>
          </Row>
        </Dashboard.Content>
      </Scroll>
    </Form>
    <Signer.ListModal
      show={showModalSignerList}
      onClose={() => setShowModalSignerList(false)}
      newSigners={newSigners}
      setNewSigners={setNewSigners}
      removeNewSigner={removeNewSigner}
    />
  </>);
}

export default DocumentSigners;