import React, { useState, useEffect } from 'react'
import { Outlet, useOutletContext, useParams, useLocation, useNavigate } from 'react-router-dom'
import { getSession } from '../api/Assinafy';
import Prepare from '../layout/Prepare';
import _ from 'lodash';

const PrepareRouteTemplateUse = ({ virtual }) => {
  const [documentContext, setDocumentContext] = useState({})
  const [signersContext, setSignersContext] = useState([])
  const [fieldsContext, setFieldsContext] = useState([])
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = getSession()
  const { id: documentId } = useParams()
  const stepMatch = location.pathname.match(/.+\/([^/]+)$/)

  useEffect(() => {
    if (!user) {
      navigate('/')
    }
  }, [user, navigate])

  useEffect(() => {
    if (!virtual && stepMatch && stepMatch[1] !== 'signers') {
      if (
        !signersContext.length ||
        (stepMatch[1] === 'review' && !fieldsContext.length)
      ) {
        if (stepMatch[1] !== 'use') {
          navigate(`/dashboard/templates/${documentId}/use`);
        }
      }
    }
  }, [virtual, stepMatch, signersContext, fieldsContext, navigate, documentId])

  if (!user) {
    return (<></>)
  }

  return (
    <Prepare> 
      <Outlet context={[
        documentContext, setDocumentContext,
        signersContext, setSignersContext,
        fieldsContext, setFieldsContext
      ]} />
    </Prepare>
  );
};

export function usePrepareContext() {
  return useOutletContext();
}

export default PrepareRouteTemplateUse;
