import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from "react";
import classNames from "classnames";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Alert, Icon, Button } from "../";

import "./styles.scss";

const customValidator = (file, maxFilesize) => {
  if (file.size > maxFilesize) {
    return {
      code: "size-too-large",
      message: `O tamanho é maior que ${maxFilesize} bytes`,
    };
  }

  return null;
};

const validatorMessage = (error) => {
  switch (error?.code) {
    case "file-invalid-type":
      return "O tipo de arquivo selecionado é inválido";
    default:
      return "Erro ao importar arquivo";
  }
};

const Dropzone = forwardRef(
  (
    {
      renderFile,
      show,
      size,
      acceptedExts,
      onSubmit,
      onComplete,
      onInit,
      onOpen,
      onRemove,
      maxFilesize,
      maxHeight,
      maxWidth,
      errorsCallback,
      ...props
    },
    ref
  ) => {
    const { children, className } = props;
    const prefix = "dropzone";
    const newClassName = classNames(
      prefix,
      size && `${prefix}-${size}`,
      className
    );
    const [files, setFiles] = useState([]);
    const [filesErrors, setFilesErrors] = useState([]);
    const [progress, setProgress] = useState(0);
    const styleToHide = { display: "none" };

    if (typeof acceptedExts === "undefined") {
      acceptedExts = {
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.oasis.opendocument.text": [".odt"],
      };
    }

    if (typeof show === "undefined") {
      show = true;
    }

    const onDrop = useCallback(
      (acceptedFiles) => {
        setProgress(0);
        setFiles([]);
        setFilesErrors([]);

        if (onInit) onInit();

        acceptedFiles.map((file, i) => {
          if (/^image\//.test(file.type)) {
            const image = new Image();
            image.onload = () => fileProcess(file, i, image);
            image.src = URL.createObjectURL(file);
          } else {
            fileProcess(file, i);
          }
        });
      },
      [onInit]
    );

    const fileProcess = (file, i, image) => {
      const fileUpload = {
        progress: 0,
        source: axios.CancelToken.source(),
      };
      const fileMeta = {
        upload: fileUpload,
        status: "queue",
        preview: URL.createObjectURL(file),
      };
      const formData = new FormData();
      formData.append("file", file);
      // if (typeof image !== 'undefined') {
      //   if (image.width > maxWidth || image.height > maxHeight) {
      //     return setFilesErrors([
      //       ...filesErrors,
      //       `As dimensões recomendadas é ${maxWidth} x ${maxHeight} pixels. Sua imagem tem ${image.width} x ${image.height} pixels.`
      //     ])
      //   }
      // }

      setFiles((prevFiles) => [...prevFiles, Object.assign(file, fileMeta)]);

      onSubmit(formData, {
        cancelToken: fileUpload.source.token,
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          const progress = Math.round((loaded * 100) / total);
          if (progress < 100) {
            fileUpload.progress = progress;
            setProgress(progress * (i + 1));
          }
        },
      })
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            file.data = data;
            file.status = "success";
          } else {
            file.status = "error";
          }

          fileUpload.progress = 100;
          setProgress(100 * (i + 1));
        })
        .catch((error) => {
          file.status = "error";
          setProgress(100 * (i + 1));
        });
    };

    const onDropRejected = useCallback(
      (fileRejections) => {
        const file = fileRejections[0];

        setFiles((prevFiles) => [
          ...prevFiles,
          Object.assign(
            file.file,
            { errors: file.errors },
            {
              status: "error",
            }
          ),
        ]);
      },
      [onInit]
    );

    const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
      accept: acceptedExts,
      maxFiles: 1,
      multiple: false,
      onDrop,
      onDropRejected,
      validator: (file) => customValidator(file, maxFilesize),
    });

    const handleRemove = (index) => {
      const file = files[index];
      const newFiles = [...files];

      if (file && file.upload) {
        file.upload.source.cancel();

        acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
      }

      newFiles.splice(index, 1);
      setFiles(newFiles);

      if (onRemove) onRemove();
    };

    const handleOpen = (id) => {
      if (onOpen) onOpen(id);
    };

    useImperativeHandle(ref, () => ({
      open,
      clearErrors: () => setFilesErrors([]),
      removeAll: (callback) => {
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);

        setFiles([]);

        if (callback) callback();
      },
    }));

    useEffect(() => {
      let uploadComplete = true;

      if (files.length) {
        files.map((file) => {
          if (file.status === "queue") {
            uploadComplete = false;
          }
        });
      } else {
        uploadComplete = false;
      }

      if (uploadComplete) {
        if (onComplete) onComplete();

        return () => {
          setFiles([]);
        };
      }
    }, [progress]);

    useEffect(() => {
      if (typeof errorsCallback !== "undefined" && filesErrors.length) {
        errorsCallback(filesErrors);
      }
    }, [errorsCallback, filesErrors]);

    return (
      <>
        {!!filesErrors.length && typeof errorsCallback === "undefined" && (
          <Alert variant="warning" onClose={() => setFilesErrors([])}>
            {filesErrors.map((error) => error)}
          </Alert>
        )}

        <div
          {...props}
          className={newClassName}
          style={!show ? styleToHide : {}}
        >
          <input {...getInputProps()} />
          {!files.length && (
            <div {...getRootProps()} className="dz-message">
              {children}
            </div>
          )}
          {files.map((file, i) => {
            return renderFile({
              file,
              onOpen: () => handleOpen(file.data.id),
              onRemove: () => handleRemove(i),
            });
          })}
        </div>
      </>
    );
  }
);

const DropzoneDisabled = forwardRef(
  (
    {
      acceptedExts,
      maxFilesize,
      maxHeight,
      maxWidth,
      renderFile,
      errorsCallback,
      ...props
    },
    ref
  ) => {
    const { children, className } = props;
    const newClassName = classNames("dropzone", "disabled", className);

    return (
      <div {...props} className={newClassName}>
        <div className="dz-message">{children}</div>
      </div>
    );
  }
);

const DropzoneDocument = forwardRef(
  ({ buttonText, buttonSecondary, buttonIcon, ...props }, ref) => {
    const getFileExtension = (name) => name && name.match(/\.([0-9a-z]+)$/i)[1];
    const getFileSize = (size) =>
      size && `${Math.round(size / 1024).toLocaleString("pt-BR")} kb`;

    const handleClickGoogleDrive = (event) => {
      event.stopPropagation();
      alert("google drive");
    };

    return (
      <Dropzone
        ref={ref}
        {...props}
        renderFile={({ file, onOpen, onRemove }) => {
          const { name, size, status, path, upload, lastModified, errors } =
            file;

          return (
            <div key={lastModified} className={`dz-preview dz-${status}`}>
              <Icon id="icon-upload_file" size="32" />
              <div className="dz-file">
                {path}
                <div className="dz-file-infos">
                  {`${getFileExtension(name)} - ${getFileSize(size)}`}
                </div>
                {status !== "queue" && (
                  <div className="dz-file-status">
                    {status === "success" && "Arquivo importado com sucesso"}
                    {status === "error" &&
                      validatorMessage(errors?.length && errors[0])}
                  </div>
                )}
              </div>
              {status === "success" ? (
                <button
                  onClick={onOpen}
                  className="btn dz-remove"
                  type="button"
                >
                  <Icon id="icon-edit" className="me-1" size="18" />
                  <span className="d-none">Preparar documento</span>
                </button>
              ) : (
                <button
                  onClick={onRemove}
                  className="btn dz-remove"
                  type="button"
                >
                  <Icon id="icon-close" className="me-1" size="18" />
                  <span className="d-none">
                    {status === "queue" ? "Cancelar" : "Enviar outro arquivo"}
                  </span>
                </button>
              )}
              {upload && (
                <div
                  className="dz-progress"
                  style={{ width: `${upload.progress}%` }}
                ></div>
              )}
            </div>
          );
        }}
      >
        <div className="dropzone-header">
          <Icon id={`icon-${props.icon}`} size="120" />
          <h1 className="h1">{props.title}</h1>
          <div className="dropzone-opening">{props.description}</div>
        </div>
        {buttonSecondary && (
          <div className="dropzone-actions">
            <Button size="lg" className="btn-icon">
              <Icon id="icon-upload_file" className="me-2" size="48" />
              Arraste e solte documentos aqui
            </Button>
            {/* <span className="or">ou</span>
        <Button size="lg" className="btn-icon" onClick={handleClickGoogleDrive}>
        <Icon id="icon-google_drive" className="me-2" size="48" />
        Importe do Google Drive
        </Button>
        <span className="or or-file">ou</span>
        <Button variant="primary" className="btn-file">
        <Icon id="icon-upload_file" className="me-1" size="18" />
        ESCOLHA UM ARQUIVO
        </Button> */}
          </div>
        )}
        {/* <Button variant="google" className="d-lg-none">
      <Icon id="icon-google_drive" className="me-1" size="18" />
      IMPORTE DO SEU GOOGLE DRIVE
    </Button> */}
        <Button variant="primary">
          <Icon id={`icon-${buttonIcon}`} className="me-1" size="18" />
          {buttonText}
        </Button>
      </Dropzone>
    );
  }
);

const DropzoneInline = forwardRef(({ disabled, ...props }, ref) => {
  let Component = Dropzone;

  if (disabled) {
    Component = DropzoneDisabled;
  }

  return (
    <Component
      ref={ref}
      {...props}
      className="dropzone-inline"
      renderFile={({ file }) => {
        const { status, preview, lastModified, errors } = file;

        return (
          <div key={lastModified} className={`dz-preview dz-${status}`}>
            {status === "success" && (
              <img src={preview} className="dz-preview-bg" alt="" />
            )}
            {status === "error" && (
              <div className="dz-file-status">
                {validatorMessage(errors?.length && errors[0])}
              </div>
            )}
          </div>
        );
      }}
    />
  );
});

Dropzone.Document = DropzoneDocument;
Dropzone.Inline = DropzoneInline;

export default Dropzone;
